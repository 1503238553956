import NoSearchResults from "@components/CardComp/NoSearchResults";
import LoadingComp from "@components/LoadingComp";
import { PatentOccurTrend } from "@pages/Main/Graph";
import { commaNumber } from "@utils/index";
import { IPatentTrendTotalData } from "~types/graphTypes";

const CountryPatentsOccurredCount = ({ isLoading, patentTrendTotal, patentTrend }: IPatentTrendTotalData) => {
    return (
        <>
            <div className="c__dash">
                {patentTrendTotal?.map((item, index) => (
                    <div key={index}>
                        <span className="p__number revenue">총 {commaNumber(item.data)}</span>
                        <span className="p__unit">{item?.name?.toUpperCase()}</span>
                    </div>
                ))}
            </div>
            {isLoading ? (
                <div className="c__loading ">
                    <LoadingComp />
                </div>
            ) : (
                <>
                    {patentTrend &&
                        (patentTrend?.data?.length === 0 ? (
                            <NoSearchResults />
                        ) : (
                            <div>
                                <PatentOccurTrend data={patentTrend.data} label={patentTrend.label} />
                            </div>
                        ))}
                </>
            )}
        </>
    );
};

export default CountryPatentsOccurredCount;
